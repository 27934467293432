import { Table } from '@components/index';
import { actionHandler, PlannerActions } from '@store/actions';
import { getTableListSelector } from '@store/selectors';
import React, { useState } from 'react';
import { connect } from 'react-redux';

function App({ dispatchAction, screenDataList }) {
  const [loading, setLoading] = useState(false);

  function fetchTableData() {
    dispatchAction(PlannerActions.FETCH_DOOH_SCREENS, {
      setLoading,
    });
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
      <div>
        <button
          onClick={() => {
            fetchTableData();
          }}
          disabled={loading}
        >
          GEt Table Data
        </button>
      </div>
      <Table data={screenDataList} tableLoading={loading} />
    </div>
  );
}

const mapStateToProps = (state) => {
  return { screenDataList: getTableListSelector(state) };
};

const mapDispatchToProps = (dispatch) => ({
  dispatchAction: (type, payload) => {
    dispatch(actionHandler(type, payload));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
