import { PlannerActions } from '@store/actions';
import { sagaAPIMethods } from '@utils/requestHandler';
import { call, fork, put, take } from 'redux-saga/effects';

function* fetchDoohScreensSaga() {
  try {
    while (true) {
      const data = yield take(PlannerActions.FETCH_DOOH_SCREENS);

      const { setLoading, onError, onSuccess } = data.payload;

      const url = '/api/screens';

      setLoading?.(true);

      const response = yield call(sagaAPIMethods.get, url);

      if (response?.status >= 400 || response?.data?.error) {
        onError?.(response.data.error);
        setLoading?.(false);
      } else {
        yield put({
          type: PlannerActions.FETCH_DOOH_SCREENS_SUCCESS,
          payload: response.data.data,
        });

        onSuccess?.();
        setLoading?.(false);
      }
    }
  } catch (errors) {
    // eslint-disable-next-line
    console.log('fetchDoohScreensSaga', errors);
  }
}

export default function* () {
  yield fork(fetchDoohScreensSaga);
}
