import React from 'react';
import './index.scss';

const Table = ({ data, tableLoading }) => {
  if (tableLoading) return 'Loading Data...';

  if (!data.length) return;

  const headers = Object.keys(data[0]);

  return (
    <table>
      <thead>
        {headers.map((item) => {
          return <th key={item}>{item}</th>;
        })}
      </thead>
      <tbody>
        {data.map((item) => {
          return (
            <tr key={item.id}>
              {Object.keys(item).map((value) => {
                return <td key={item.id}>{item[value]}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;
