import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './scss/index.scss';
import { Provider } from 'react-redux';
import store from '@store/index';
import { setAxiosBaseUrl } from '@utils/requestHandler';

const Main = () => {
  setAxiosBaseUrl(import.meta.env.VITE_APP_API_BASE_URL);

  return <App />;
};

createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <Main />
  </Provider>
);
