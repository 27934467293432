import axios from 'axios';

const axiosInstance = axios.create({});

export const getAxiosInstance = () => axiosInstance;

export const setAxiosBaseUrl = (baseUrl) => {
  getAxiosInstance().defaults.baseURL = baseUrl;
};

const get = (url, params, headers) =>
  new Promise((resolve) => {
    getAxiosInstance()
      .get(url, { params, headers })
      .then((response) => resolve(response))
      .catch((error) => resolve(error));
  });

const post = (url, body, headers) =>
  new Promise((resolve) => {
    getAxiosInstance()
      .post(url, body, {
        headers,
      })
      .then((response) => resolve(response))
      .catch((error) => resolve(error));
  });

const update = (url, body, headers) =>
  new Promise((resolve) => {
    getAxiosInstance()
      .put(url, body, {
        headers,
      })
      .then((response) => resolve(response))
      .catch((error) => resolve(error));
  });

const deleteCall = (url, headers, config = {}) =>
  new Promise((resolve) => {
    getAxiosInstance()
      .delete(url, {
        headers,
        ...config,
      })
      .then((response) => resolve(response))
      .catch((error) => resolve(error));
  });

export const sagaAPIMethods = {
  get,
  post,
  update,
  deleteCall,
};
