import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { PlannerReducer } from './reducers';

import rootSaga from './sagas';

const reducer = combineReducers({
  planner: PlannerReducer,
});

const isDev = import.meta.env.DEV;
const sagaMiddleware = createSagaMiddleware();

// Creates the Redux store using our reducer and the logger and saga middlewares
const composeEnhancers = isDev
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  : compose;

const middleware = [sagaMiddleware];

const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(...middleware))
);

sagaMiddleware.run(rootSaga);

export default store;
